<template>
  <div>
    <h5>Histórico do contrato</h5>
    <div v-if="loading" class="d-flex justify-content-center m-2">
        <b-spinner variant="primary" />
    </div>
    <template v-else-if="items.length > 0">
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="div"
      >
        <b-table
          :items="items"
          striped
          borderless
          small
          :fields="tableColumns"
          show-empty
          empty-text="Não foram encontrados históricos"
          :busy="loading"
        >
          <!-- Column: createdAt -->
          <template #cell(created_at)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{ data.item.created_at | dateTime }}</span>
            </div>
          </template>
          <!-- Column: event -->
          <template #cell(event)="data">
            <div class="text-wrap" >
              <span 
                class="align-text-top text-indent" 
                v-if="data.item.event_type === 'status_change'"
              >
                Mudança do status: 
                <span class="text-primary ">{{ data.item.previous_status.name }}</span>
                para:
                <span class="text-primary">{{ data.item.new_status.name }}</span>
              </span>
              <span 
                class="align-text-top text-indent" 
                v-else-if="data.item.event_type === 'comment'"
              >
                Comentário: 
                  <span class="text-primary ">{{ data.item.comment }}</span>
              </span>
            </div>
          </template>
          <!-- Column: user -->
          <template #cell(user)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{ data.item.user.name }}</span>
            </div>
          </template>
        </b-table>
      </vue-perfect-scrollbar>
    </template>
    <template v-else>
      <span class="d-flex justify-content-center" >
        Não foram encontrados históricos
      </span>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardHeader, BCardBody, BSpinner
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as sharedTypes from '@/modules/shared/store/types'
import * as types from '../store/types'
import { integer } from 'vee-validate/dist/rules'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    VuePerfectScrollbar
  },
  setup() {
    return { toast: useToast() }
  },
  props: {
    contract_id: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      tableColumns: [
        { key: 'created_at', label: 'Data' },
        { key: 'event', label: 'Evento' },
        { key: 'user', label: 'Responsável' }
      ],
    }
  },
  computed: {
    ...mapGetters({
      history: types.HISTORY
    }),
    items: function () {
      if(this.history){
        return _.orderBy(this.history, function(element) {
          return new Date(element.created_at);
        }, ['desc']);
      }
      return []
    },
    total: function(){
      return this.history?.total || 0
    },
  },
  mounted() {
    this.get()
  },
  methods: {
    ...mapActions({
      getHistory: types.GET_HISTORY,
      deleteComment: types.DELETE_COMMENT,
      openCommentSidebar: sharedTypes.OPEN_COMMENT_SIDEBAR,
      saveComment: types.SAVE_COMMENT
    }),
    get() {
      this.loading = true
      this.getHistory({
        id: this.contract_id
      })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao buscar o histórico. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.ps {
  height: 50vh;
  font-size: smaller;
}
@media (max-height: 700px)  {
  .ps {
    height: 30vh;
  }
}
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
