<template>
  <div>
    <b-sidebar
      id="sidebar-notification"
      sidebar-class="sidebar-lg"
      :visible="notificationSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateNotificationSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Notificação</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="justify-content-between">
          <div class="m-2">
            <h4>[{{ notificationSidebar.title }}]</h4>
            <span v-html="message"></span>
          </div>
          <div class="m-2" v-if="contract_id">
            <contract-history :contract_id="contract_id" />
          </div>
          <div class="m-2 sidebar-bottom" v-if="contract_id">
            <b-form @submit.prevent="saveContractComment">
              <b-form-group label="Comentário" label-for="comment-notification">
                <b-form-textarea
                  id="comment-notification"
                  v-model="comment"
                  :class="{ 'is-invalid': v$.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.$error">
                    Você deve informar um comentário
                  </span>
                </div>
              </b-form-group>
              <b-button
                :disabled="saving"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Comentar
              </b-button>
              <b-button
                :disabled="saving"
                variant="secondary"
                class="mr-2"
                @click="openContract"
              >
                Abrir contrato
              </b-button>
            </b-form>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import * as types from "../store/types";
import * as contractTypes from "@/modules/contract/store/types";
import ContractHistory from "@/modules/contract/components/ContractHistory";
import getNotificationMessage from "@/libs/notification";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    ContractHistory,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      description: undefined,
      contract_id: undefined,
      comment: undefined,
    };
  },
  computed: {
    ...mapGetters({
      notificationSidebar: types.NOTIFICATION_SIDEBAR,
    }),
    message: function () {
      return getNotificationMessage(
        this.notificationSidebar.metadata,
        this.notificationSidebar.type,
        this.notificationSidebar.tag
      );
    },
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      contract_id: { required },
      comment: { required },
    };
  },
  methods: {
    ...mapMutations({
      mutateNotificationSidebar: types.MUTATE_NOTIFICATION_SIDEBAR,
    }),
    ...mapActions({
      readNotification: types.READ_NOTIFICATION,
      getUnreadNotifications: types.GET_UNREAD_NOTIFICATIONS,
      saveComment: contractTypes.SAVE_COMMENT,
    }),
    onShow() {
      this.contract_id =
        this.notificationSidebar.metadata?.contract_id || undefined;
      if (this.notificationSidebar.markAsRead) {
        this.readNotification({ id: this.notificationSidebar.id })
          .then(() => {
            this.getUnreadNotifications().catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao carregar as notificações. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao marcar a notificação. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    async saveContractComment() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      this.saveComment({
        id: this.contract_id,
        comment: this.comment,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "O comentário foi salvo com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateNotificationSidebar({ visible: false });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar o comentário. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    openContract() {
      let id = this.contract_id;
      let routeData = this.$router.resolve({
        name: "contract-details-summary",
        params: { id },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    clear() {
      this.contract_id = undefined;
      this.comment = undefined;
    },
  },
};
</script>

<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>
